import { useContext, useEffect } from 'react';
import get from 'lodash/get';
import { FormContext } from './FormContext';

export const useForm = ({
  name,
  defaults = '',
  constraints = {},
  required = false,
}) => {
  const formContext = useContext(FormContext);

  useEffect(() => {
    const formRefs = { ...constraints, required };
    if (formContext.formRefs) {
      formContext?.formRefs(name, { constraints: formRefs });
    }
  }, [name]);

  const setValue = value => {
    const formData = new FormData();
    formData.append(name, value);
    formContext.formAction(formData);
  };
  return [
    get(formContext, `state.${name}`, defaults),
    get(formContext, `state.errors["${name}"]`),
    setValue,
  ];
};
