import { Checkbox } from './Checkbox';
import { Label } from './Label';
import css from './hub/form.module.scss';

interface Props {
  label: string;
  data: { value: string; label: string }[];
  name: string;
}
export const CheckboxGroup = (props: Props) => {
  const { label, data = [], name } = props;

  return (
    <div className={css.formWrapper}>
      <Label label={label} />
      <div className={css.checkboxGroup}>
        {data.map(item => (
          <Checkbox
            key={item.value}
            name={`${name}.${item.value}`}
            label={item.label}
          />
        ))}
      </div>
    </div>
  );
};
