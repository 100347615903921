import routeConfig from '../docs/routes';
import { Image } from '../web/Image';
import { Routes } from '../web/Routes';
import { Sandbox } from '../web/Sandbox';
import { Sidebar } from '../web/Sidebar';

const components = {
  pre: props => <div {...props} />,
  code: props => {
    return <Sandbox.Code {...props} />;
  },
};

export const App = () => {
  return (
    <div>
      <Sidebar>
        <Sidebar.Header
          logo={<Image src="logo/compact.svg" type="logo" />}
          hash="compact"
          showPrint
          showTheme
        />
        <Sidebar.Menu
          content={<Routes routes={routeConfig} cloneProps={{ components }} />}
        >
          <Sidebar.MenuRoute href="#/typography">Typography</Sidebar.MenuRoute>
          <Sidebar.MenuRoute href="#/colors">Colors</Sidebar.MenuRoute>
          <Sidebar.MenuRoute href="#/icons">Icons</Sidebar.MenuRoute>
          <Sidebar.Divider>Components</Sidebar.Divider>
          <Sidebar.MenuRoute href="#/accordion">Accordion</Sidebar.MenuRoute>
          <Sidebar.MenuRoute href="#/box">Box</Sidebar.MenuRoute>
          <Sidebar.MenuRoute href="#/button">Button</Sidebar.MenuRoute>
          <Sidebar.MenuRoute href="#/card">Card</Sidebar.MenuRoute>
          <Sidebar.MenuRoute href="#/datagrid">Datagrid</Sidebar.MenuRoute>
          <Sidebar.MenuRoute href="#/tabs">Tabs</Sidebar.MenuRoute>
          <Sidebar.MenuRoute href="#/dialog">Dialog</Sidebar.MenuRoute>
          <Sidebar.MenuRoute href="#/popover">Popover</Sidebar.MenuRoute>
          <Sidebar.MenuRoute href="#/toast">Toast</Sidebar.MenuRoute>
          <Sidebar.MenuRoute href="#/codebox">CodeBox</Sidebar.MenuRoute>
          <Sidebar.Divider>Display</Sidebar.Divider>
          <Sidebar.MenuRoute href="#/flex">Flex</Sidebar.MenuRoute>
          <Sidebar.MenuRoute href="#/grid">Grid</Sidebar.MenuRoute>
          <Sidebar.MenuRoute href="#/stack">Stack</Sidebar.MenuRoute>
          <Sidebar.Divider>Form</Sidebar.Divider>
          <Sidebar.MenuRoute href="#/form">Form</Sidebar.MenuRoute>
          <Sidebar.Divider>Tools</Sidebar.Divider>
          <Sidebar.MenuRoute href="#/yiq">YIQ</Sidebar.MenuRoute>
        </Sidebar.Menu>
      </Sidebar>
    </div>
  );
};
