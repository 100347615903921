import { useContext } from 'react';
import { SidebarContext } from './SidebarContext';
import { Button } from '../Button';
import { useTheme } from '../context/ThemeContext';
import { Icon } from '../Icon';
import css from './hub/sidebar.module.scss';

type Props = {
  children?: React.ReactNode;
  logo: React.ReactNode;
  hash?: string;
  showTheme?: boolean;
  showPrint?: boolean;
  content?: React.ReactNode;
};

export const SidebarHeader = (props: Props) => {
  const { logo, hash, showPrint, showTheme, content } = props;
  const context = useContext(SidebarContext);
  const theme = useTheme();
  return (
    <header className={css.header} color="primary-f">
      <div className={css.headerContent}>
        <section className={css.navLeft}>
          <div
            onClick={() => {
              if (hash) {
                window.location.hash = hash;
              }
            }}
          >
            {logo}
          </div>
        </section>
        <section className={css.navRight}>
          {content}
          {showTheme && (
            <>
              <Button onClick={() => theme.setHubTheme('light')}>Light</Button>
              <Button onClick={() => theme.setHubTheme('dark')}>Dark</Button>
            </>
          )}
          {showPrint && <Button onClick={window.print}>Print</Button>}
          <div className={css.navMenu}>
            <Button color="tertiary-f" onClick={context.onToggle}>
              <Icon>menu</Icon>
            </Button>
          </div>
        </section>
      </div>
    </header>
  );
};
