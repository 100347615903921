import { createContext, useEffect, useState } from 'react';
import page from '../../tools/page';
import { IDBContext } from '../IDBContext';
import { ThemeContext } from '../ThemeContext';

type AppCtxtType = {
  appKey: string;
};

export const AppCtxt = createContext<AppCtxtType>({} as AppCtxtType);

type AppContextType = {
  appKey: string;
  children: React.ReactNode;
  db?: {
    stores: [];
  };
};

export const AppContext = ({ children, appKey, db }: AppContextType) => {
  const [loading, setLoading] = useState(false);

  const initialize = () => {
    let dbPromise;
    if (db) {
      dbPromise = page.db.init(appKey, { ...db.stores, ...page.stores });
    }
    Promise.resolve(dbPromise).then(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <IDBContext db={db}>
      <AppCtxt value={{ appKey }}>
        <ThemeContext>{children}</ThemeContext>
      </AppCtxt>
    </IDBContext>
  );
};
